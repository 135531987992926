h1, h2, h3, h4, h5, h6 {
  color: #242424; }


body {
  min-height: 100vh; }

header {
  background: white;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2); }


.dragscroll {
  cursor: grab; }


.dragscroll:active {
  cursor: grabbing; }

.header-container {
  max-width: 1280px;
  padding: 25px 32px;
  margin: 0 auto;

  > div {
    max-height: 50px;
    display: inline-block;
    vertical-align: middle; }


  .logo {
    height: 32px;
    margin: 9px auto; }

  .header-link {
    line-height: 50px; }

  .header-items {
    float: right; } }

body {
  background: #F7F7F7; }


#awesome {
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px;
  //height: calc(100vh - 100px)

  > div {
    //display: flex
    //align-items: flex-start

    > div {
      //height: calc(100vh - 164px)
      display: inline-block;
      vertical-align: top; } } }

#fb-discuss {
  margin: 32px -8px 0;

  iframe {
    width: 100% !important; } }

.left-col {
  width: 186px;
  margin-right: 32px;
  //height: calc(100vh - 100px)
  height: inherit;

  .menu {
    //height: calc(100vh - 423px)
    //overflow-y: auto
    margin: 16px 0; } }

.mid-col {
  width: 250px;
  //background: #fff
  margin-right: 32px;
  //overflow-y: auto

  a {
    padding: 0 24px;
    color: #242424 !important; }

  .sidebar {
    background: #fff;
    padding: 16px 0; } }


.right-col {
  width: calc(100% - 500px);

  .mobile-sort {
    display: none; }

  .category-tags {
    margin: 0 !important; } }

.right-full-col {
  width: calc(100% - 218px); }

.search {
  width: 100%;
  position: relative;

  .icon {
    height: 50px !important;
    width: 50px !important;
    color: #dbdbdb;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
    right: 0;
    align-items: center;
    display: inline-flex;
    justify-content: center; }

  input {
    padding: 16px;
    height: 50px;
    font-family: sans-serif;
    font-weight: 700;
    border: 1px solid #F2F2F2;
    border-radius: 1px;
    padding-right: 2.25em; } }

.sidebar-categories {
  margin: 16px 0;

  a {
    font-size: 16px;
    //margin-bottom: 16px
    text-transform: capitalize;
    line-height: 32px;
    color: #666666;
    display: block; } }

hr {
  margin: 0;
  background-color: #CCCCCC;
  height: 1px; }

aside.menu {

  a {
    line-height: 40px;
    font-size: 16px;
    display: block;
    color: #666666; }

  .sub-cat {
    padding-left: 16px; } }

.is-active {
  font-weight: bold; }

.category-listing {
  background-color: #FFF;
  height: 100%; }

.detail-listing {
  background-color: #FFF;
  height: 100%; }

.listing-heading {
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, .2);

  h1 {
    line-height: 100px;
    font-size: 32px;
    font-weight: bold;
    padding: 0 32px;

    a {
      color: #242424; } }

  .fa-chevron-left {
    vertical-align: unset !important;
    font-size: 20px;
    margin-right: 16px; } }

.listing-subheading {
  padding: 0 32px;

  > * {
    display: inline-block; }

  h2 {
    font-size: 24px;
    line-height: 88px;
    //line-height: 1.5
    font-weight: bold; }

  h6 {
    display: block;
    padding-bottom: 32px; }

  .select-wrapper {
    vertical-align: middle;
    float: right;
    height: 88px;
    display: flex;
    align-items: center; }

  .sort-label {
    margin-right: 8px;
    font-weight: bold; } }

.listing-body {
  padding: 0 32px; }
//overflow-y: scroll
//height: calc(100vh - 264px)


.project {
  display: flex;
  padding-bottom: 24px;

  .project-star {
    width: 64px;
    font-size: 16px;
    text-align: center;
    margin-right: 32px;
    font-weight: bold;

    p {
      color: #666666; } }

  .category-tags {
    margin: 8px -4px; }


  .fa-star {
    color: #C7C000;
    display: block;
    margin: 6px auto 12px; }

  .project-main {
    flex: 1;
    overflow: hidden; }

  .project-title {
    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #242424;
      display: inline-block; }


    a {
      color: #242424; }

    h5 {
      display: inline-block;
      float: right;
      font-size: 14px;
      color: #B0B0B0;
      vertical-align: bottom; } }


  .project-description {
    margin: 8px 0;

    p {
      color: #666666;
      font-size: 16px; } }

  .project-misc {
    p {
      font-size: 14px;
      color: #B0B0B0; } } }

.project-gitlink {
  margin-left: 16px; }

.project-detail {
  .project-tags {
    display: flex; }
  //display: none
  //max-width: 100vw
  .category-tags {}
  //max-width: 80vw
  //overflow-x: auto

  .project-header {
    display: flex;
    margin: 16px 0; }

  .project-header-left {
    flex: 1;
    overflow: auto;

    h2 {
      line-height: 56px;
      font-size: 24px;
      font-weight: bold; } }


  .project-description {
    color: #666666;
    font-size: 16px;
    margin-bottom: 8px; }

  .project-misc {
    font-size: 12px;
    color: #B0B0B0; }

  .project-star {
    line-height: 56px;
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    margin-left: 32px;

    .fa-star {
      font-size: 24px;
      color: #C7C000;
      margin-right: 8px; } }

  .project-time {

    padding: 16px 32px;
    background: #F7F7F7;

    .time-block {
      width: 33%;
      display: inline-block; }

    .time-heading {
      font-size: 12px;
      color: #8D8800;
      margin-bottom: 8px;
      font-weight: bold; }

    .time-content {
      font-size: 20px;
      color: #666666;
      text-transform: uppercase; } }


  .project-issues {
    margin: 32px 0;

    .project-issues-title {
      font-size: 20px;
      font-weight: bold;
      Line-height: 23px;
      margin-bottom: 16px; }

    .project-issue {
      margin-bottom: 8px;
      display: flex;

      img {
        display: inline-block;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 8px; }

      .project-issue-content {
        display: inline-block; }

      .project-issue-title {
        font-size: 16px;
        line-height: 24px;
        color: #242424;
        margin-bottom: 6px;
        word-break: break-all; }

      .project-issue-misc {
        font-size: 12px;
        color: #B0B0B0; } } } }

.project-readme {
  margin-bottom: 32px;
  overflow-x: hidden; }

.home {
  background: #fff;
  padding: 16px 32px;

  h2 {
    line-height: 88px;
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize; } }

.recent-blocks {
  margin: 0 -8px;

  .recent-block {
    width: calc(33.33% - 16px);
    padding: 16px;
    margin: 0 8px;
    display: inline-block;
    border: 1px solid #CCCCCC;
    vertical-align: top; }

  h3 {
    text-overflow: ellipsis;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold; }

  .recent-misc {
    margin: 8px 0; }

  .recent-star {
    line-height: 20px;
    font-size: 14px;
    color: #666666;
    font-weight: bold;

    .fa-star {
      font-size: 20px;
      color: #C7C000;
      margin-right: 8px; } }

  .recent-time {
    line-height: 20px;
    font-size: 14px;
    float: right;
    color: #B0B0B0; } }

.category-tags {
  margin: 0 -4px;
  line-height: 32px;
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */








  &::-webkit-scrollbar {
    width: 0px;
    display: none; }

  span {
    background: #8D8800;
    border-radius: 8px;
    font-size: 14px;
    padding: 4px 8px;
    font-weight: bold;
    margin: 0 4px;
    white-space: nowrap; }

  a {
    color: #fff; }

  .category-type {
    background: #C700B8; }

  .category-sublist {
    background: #C7C000; } }

.mobile-show {
  display: none; }

@media (max-width: 1023px) {
  html {}
  //overflow: hidden
  body {
    background: #FFF; }
  header {
    box-shadow: none; }
  .mobile-show {
    display: unset; }
  .mobile-hide {
    display: none !important; }
  .header-container {
    display: none; }
  .is-hidden-mobile {
    display: none !important; }

  #awesome {
    padding: 0;
    position: relative;


    .left-col {
      display: block;
      width: 100%;
      //position: absolute
      //left: -100vw
      //transition: all 0.5s
      &.is-shown {}
      //left: 0
      //height: 100vh
      //display: none
      .sidebar-sticky {
        padding: 16px 32px;
        display: block;
        text-align: center;
        position: fixed;
        top: 0;
        z-index: 999;
        width: 100%;
        background: #F7F7F7;

        img {
          height: 28px;
          margin: 11px auto; }

        a {
          line-height: 50px;
          float: right;
          position: absolute;
          top: 16px;
          right: 16px;
          padding: 0 16px;
          font-size: 16px;
          color: #242424; } }

      .sidebar {
        padding: 16px 32px;
        background: #F7F7F7;
        min-height: 100vh; }
      //height: 100vh
      //overflow-y: auto
      //-webkit-overflow-scrolling: touch

      .logo {
        text-align: center; }

      .sidebar-top {
        margin-top: 90px; } }

    .mid-col {
      display: block;
      width: 100%;

      li {
        padding: 0 32px; }

      .sidebar {
        //height: 100vh
        padding: 0;

        .menu {
          margin-top: 72px; } } }

    .right-col {

      .project-title h5 {
        margin-bottom: 0 !important; }

      .category-listing {
        margin-top: 56px; }

      .mobile-sort {
        padding: 8px 16px;
        display: flex;

        .sort-label {
          line-height: 2.25em;
          font-weight: bold; }

        .select {
          margin-left: auto; }

        select {
          border: unset; } } }

    .category-listing {
      width: 100%;
      position: absolute;
      z-index: 6;
      display: block;
      height: 100vh;
      margin-top: 72px; }

    .listing-heading {
      padding: 0 16px;
      margin-bottom: 16px;
      display: block;
      height: 56px;
      position: fixed;
      width: 100%;
      top: 0;
      background: #FFF;
      z-index: 5;
      //.fa-chevron-left
      //  vertical-align: middle !important
      h1 {
        margin-left: -16px;
        font-size: 20px;
        line-height: 56px;
        padding: 0; }

      a {
        padding: 10px 32px 10px 16px; }

      .svg-inline--fa {
        font-size: 12px;
        vertical-align: unset; } }
    //margin-right: 32px

    .listing-subheading {
      background: #F7F7F7;
      padding: 16px 32px;

      h2 {
        display: block;
        font-size: 16px;
        line-height: 32px; }
      h6 {
        padding-bottom: 0; }

      span.more {
        float: right; }

      a {
        color: #8D8800; } }

    .listing-body {
      //overflow-y: auto
      //-webkit-overflow-scrolling: touch
      //height: calc(100vh - 100px)
      padding: 0 12px 16px;
      background: #fff; }

    .project {
      margin: 12px 0;

      .project-star {
        margin-right: 12px; }

      .project-title {
        h4 {
          display: block;
          word-break: break-all;
          font-size: 18px; }

        h5 {
          font-size: 12px;
          margin: 8px 0; } }

      .project-lastseen {
        display: block;
        float: left;
        margin-bottom: 8px; }

      .category-tags {
        clear: both;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; } }

    .right-full-col {
      width: 100%; }

    .detail-listing {
      width: 100%;
      position: absolute;
      z-index: 6;
      display: block;
      //height: 100vh

      .listing-body {
        padding: 0 16px 32px; } }

    .project-detail {
      margin-top: 56px;

      .project-tags {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .project-star {
          display: flex;
          line-height: 32px;
          margin-left: 8px;
          align-items: center; } }

      .project-header {
        h2 {
          font-size: 20px;
          font-weight: bold;
          line-height: 52px; } }

      .project-time {
        padding: 16px 16px;
        margin: 0 -16px; }

      .time-content {
        font-size: 14px; } }

    .home {
      padding: 0;

      .home-content {
        padding: 0 16px 32px;
        margin-top: 56px;
        overflow: hidden;

        .pagination-list.mobile-show {
          display: flex;
          margin-bottom: 32px;

          li {
            flex-grow: unset; } } }

      .listing-heading {
        text-transform: capitalize; }

      .recent-blocks {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin: 0 -16px;
        //width: 100%
        white-space: nowrap; }

      .recent-block {
        min-width: 300px; } }

    .discuss-content {
      margin-top: 16px; }

    .content {
      //overflow-x: auto

      h1 {
        font-size: 20px; }

      h2 {
        font-size: 18px; }

      h3 {
        font-size: 16px; }

      h4 {
        font-size: 16px; } }

    .project-bottom-padding {
      height: 1px;
      width: 100%; } } }
//font-size: 30px
