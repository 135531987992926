@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

@import '../node_modules/bulma/sass/utilities/_all.sass';
// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$body-color: #666666;
$content-heading-color: #242424;
// Import only what you need from Bulma
//@import '../node_modules/bulma/bulma.sass'

@import '../node_modules/bulma/sass/base/_all.sass';
//@import '../node_modules/bulma/sass/grid/_all.sass'
//
//@import '../node_modules/bulma/sass/elements/button.sass'
//@import '../node_modules/bulma/sass/elements/container.sass'
@import '../node_modules/bulma/sass/form/shared.sass';
@import '../node_modules/bulma/sass/form/select.sass';
@import '../node_modules/bulma/sass/form/input-textarea.sass';
@import '../node_modules/bulma/sass/elements/content.sass';
@import '../node_modules/bulma/sass/components/pagination.sass';
//@import '../node_modules/bulma/sass/layout/hero.sass'
//@import '../node_modules/bulma/sass/layout/section.sass'


//Import branding
@import './css/nprogress.sass';
@import './css/branding.sass';
@import './css/global.sass';
